import { sendEmail } from "../services/cars-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	isPopupVisible: false,
	data: []
}

const SEND_EMAIL = "SEND_EMAIL"

export const sendEmailAction = (data) => ({
	type: SEND_EMAIL,
	payload: sendEmail(data)
})

export default (state = initialState, action) => {
	switch (action.type) {
		case SEND_EMAIL + "_PENDING":
			return { ...initialState, loading: true }
		case SEND_EMAIL + "_REJECTED":
			return { ...initialState, error: true }
		case SEND_EMAIL + "_FULFILLED":
			return { ...initialState, fetched: true,  data: action.payload.data }
		default:
			return state
	}
};
