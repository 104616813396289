import React from "react"
import FourthStepFirstTab from "../components/fourthStepFirstTab/FourthStepFirstTab";

const Step4 = () => {
	return (
		<>
			<FourthStepFirstTab/>
		</>
	)
};

export default Step4
