import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {Header} from "../common/Header";
import {CategoryHeader} from "../common/CategoryHeader";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import CustomSelect from "../common/CustomSelect";
import {useDispatch, useSelector} from "react-redux"
import {fetchMartialStatusAction} from "../../state/martialStatus";
import {fetchYesOrNoAction} from "../../state/yesOrNo";
import {fetchGendersAction} from "../../state/genders";
import {setFormValueAction} from "../../state/form";
import {ContentWrapper} from "../common/ContentWrapper";
import CustomDatePicker from "../common/CustomDatepicker";
import EditableTextInput from "../common/EditableTextInput";
import {ErrorMessage, Formik} from "formik";
import {ErrorMessageText, GreenHint, TabWrapper} from "../firstStepFirstTab/FirstStepFirstTab";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import SingleBrandTilt from "../singleBrandTilt/SingleBrandTilt";
import {CustomWrapper, RadioButtonsTwoOptionsWrapper} from "../firstStepSecondTab/FirstStepSecondTab";
import RadioButtonTwoOptions from "../common/SingleRadioButtonTwoOptions";
import RadioButtonBullet from "../common/SingleRadioButtonBullet";
import Men from "../../images/Genders/mezczyzna.svg"
import Women from "../../images/Genders/kobieta.svg"
import Popup from "../popup/Popup"
import {setFourthStepFilled} from "../../state/setPagesAreFilled"
import {fetchMeetingHoursAction} from "../../state/meetingHours";
import {togglePopupVisibility} from "../../state/showPopup";
import SingleCheckbox from "../singleCheckbox/SingleCheckbox";
import {sendEmailAction} from "../../state/sendEmail";

const RadioButtonsInRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	margin-top: 10px;
`

const RadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 10px 0 0;
	&.first {
		margin-top: 0;
	}
`

const CheckboxWrapper = styled.div`
	margin: 30px 0;
`

const FourthStepFirstTab = () => {
	const [alreadySubmitting, setAlreadySubmitting] = useState(false);
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)
	const martialStatusReducer = useSelector(state => state.martialStatus)
	const yesOrNoReducer = useSelector(state => state.yesOrNo)
	const setPagesAreFilledReducer = useSelector(state => state.setPagesAreFilled);
	const gendersReducer = useSelector(state => state.genders)
	const meetingHoursReducer = useSelector(state => state.meetingHours)
	const isPopupShown = useSelector((state) => state.showPopup.isPopupShown);
	const isSubmitting = setPagesAreFilledReducer.fourthStepFilled;

	useEffect(() => {
		dispatch(fetchMartialStatusAction())
		dispatch(fetchYesOrNoAction())
		dispatch(fetchGendersAction())
		dispatch(fetchMeetingHoursAction())
	}, [])

	useEffect(() => {
		if (isSubmitting && !alreadySubmitting) {
			setAlreadySubmitting(true);
			dispatch(sendEmailAction(formReducer))
			dispatch(togglePopupVisibility(true))
			dispatch(setFourthStepFilled(false))
		}
	}, [isSubmitting])

	const currentDate = new Date();
	const calendarDateFormat = "dd/MM/yyyy";

	const [isDataProcessingAccepted, toggleAccept] = useState(false);
	const handleDataProcessingAccept = () => {
		toggleAccept(!isDataProcessingAccepted);
		dispatch(setFormValueAction("dataProcessingAccepted", !isDataProcessingAccepted));
	}

	const handleClosePopup = () => {
		dispatch(togglePopupVisibility(false))
	}

	const GendersIconsArray = [Men, Women]
	const contactTypeData = [{
		"value": 1,
		"label": "telefon"
	}, {
		"value": 2,
		"label": "email"
	}]

	return (
		<>
			<TabWrapper>
				<Header className="tablet-hidden">Właściciel</Header>
				<GreenHint className="tablet-hidden">wypełnij formularz i porównaj ceny</GreenHint>
				<ContentWrapper>
					<Formik
						initialValues={{
							gender: formReducer.gender,
							genderLabel: formReducer.genderLabel,
							birthDate: formReducer.birthDate,
							getLicenseDate: formReducer.getLicenseDate,
							martialStatus: formReducer.martialStatus,
							martialStatusLabel: formReducer.martialStatusLabel,
							profession: formReducer.profession,
							haveChildren: formReducer.haveChildren,
							haveChildrenLabel: formReducer.haveChildrenLabel,
							contactType: formReducer.contactType,
							phoneNumber: formReducer.phoneNumber,
							emailAddress: formReducer.emailAddress,
							meetingHour: formReducer.meetingHour,
							meetingHourLabel: formReducer.meetingHourLabel,
							dataProcessingAccepted: formReducer.dataProcessingAccepted
						}}
				        validate={values => {
					        const errors = {};
					        if (!values.birthDate) {
						        errors.birthDate = 'pole wymagane'
					        }
					        if (values.birthDate) {
						        const birthDateAsDate = new Date(values.birthDate.toString());
						        const todayDate = new Date();
						        const datesDifferenceYears = ((todayDate - birthDateAsDate) / (1000 * 60 * 60 * 24 * 365));
						        const isBirthDateValid = (datesDifferenceYears >= 16);
						        if (!isBirthDateValid) {
							        errors.birthDate = 'właściciel musi mieć co najmniej 16 lat'
						        }
					        }
					        if (!values.getLicenseDate) {
						        errors.getLicenseDate = 'pole wymagane'
					        }
					        if(values.getLicenseDate) {
						        const getLicenseDateAsDate = new Date(values.getLicenseDate.toString());
						        const birthDateAsDate = new Date(values.birthDate.toString());
						        const datesDifferenceYears = ((getLicenseDateAsDate - birthDateAsDate)/(1000 * 60 * 60 * 24 * 365))
						        const isGetLicenseDateValid = (datesDifferenceYears >= 16);
						        if (!isGetLicenseDateValid) {
							        errors.getLicenseDate = 'prawo jazdy nie mogło być uzyskane w wieku poniżej 16 lat'
						        }
					        }
					        if (!values.martialStatus) {
						        errors.martialStatus = 'pole wymagane'
					        }
					        if (!values.phoneNumber && values.contactType === 1) {
						        errors.phoneNumber = 'pole wymagane'
						        errors.emailAddress = null;
					        }
					        if (!values.emailAddress && values.contactType === 2) {
						        errors.emailAddress = 'pole wymagane'
					        }
					        return errors;
				        }}
				        onSubmit={(values, {setSubmitting}) => {
					        setSubmitting(false);
					        dispatch(setFormValueAction("genderLabel", values.genderLabel))
					        dispatch(setFormValueAction("birthDate", values.birthDate))
					        dispatch(setFormValueAction("getLicenseDate", values.getLicenseDate))
					        dispatch(setFormValueAction("martialStatusLabel", values.martialStatusLabel))
					        dispatch(setFormValueAction("profession", values.profession))
					        dispatch(setFormValueAction("haveChildrenLabel", values.haveChildrenLabel))
					        dispatch(setFormValueAction("phoneNumber", values.phoneNumber))
					        dispatch(setFormValueAction("emailAddress", values.emailAddress))
					        dispatch(setFormValueAction("meetingHour", values.meetingHour))
					        dispatch(setFormValueAction("meetingHourLabel", values.meetingHourLabel))
					        dispatch(setFourthStepFilled(true))
				        }}>
						{({values, errors, isSubmitting, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
							<form onSubmit={handleSubmit}>
								<CategoryHeader className="gender">Twoja płeć</CategoryHeader>
								<RadioButtonsWrapper className="first">
									<RadioButtonsInRow>
										{gendersReducer.data.map(({label, value}, key) => (
											<SingleBrandTilt id={value}
											                 key={value}
											                 activeId={values.gender}
											                 brandIcon={GendersIconsArray[key]}
											                 brandName={label}
											                 isNotBrand={true}
												             onClick={(val) => {
													             setFieldValue("gender", val)
													             setFieldValue("genderLabel", label)
												             }}/>
										))}
										<ErrorMessageText><ErrorMessage name="gender"/></ErrorMessageText>
									</RadioButtonsInRow>
								</RadioButtonsWrapper>
								<CustomDatePicker label={"Data urodzenia"}
								                  type="date"
								                  placeholderText={"wybierz datę urodzenia"}
								                  name="birthDate"
								                  required
								                  isMarginSmall={true}
								                  selected={values.birthDate}
								                  onChange={(val) => setFieldValue("birthDate", val)}
								                  showMonthDropdown
								                  showYearDropdown
								                  dropdownMode="scroll"
								                  maxDate={currentDate}
								                  locale={'pl'}
								                  dateFormat={calendarDateFormat}
								                  pickerClassName="datepicker"/>
								<ErrorMessageText><ErrorMessage name="birthDate"/></ErrorMessageText>
								<CustomDatePicker label={"Data uzyskania prawa jazdy"}
								                  type="date"
								                  placeholderText={"wybierz datę"}
								                  name="getLicenseDate"
								                  required
								                  selected={values.getLicenseDate}
								                  onChange={(val) => setFieldValue("getLicenseDate", val)}
								                  showMonthDropdown
								                  showYearDropdown
								                  dropdownMode="scroll"
								                  maxDate={currentDate}
								                  locale={'pl'}
								                  dateFormat={calendarDateFormat}
								                  pickerClassName="datepicker"/>
								<ErrorMessageText><ErrorMessage name="getLicenseDate"/></ErrorMessageText>
								<CustomSelect selectLabel="Twój stan cywilny"
								              placeholder={"Stan cywilny"}
								              options={martialStatusReducer.data}
								              isLoading={martialStatusReducer.loading}
								              name="martalStatus"
								              selectedValue={values.martialStatus}
								              onChange={(val) => {
									              setFieldValue("martialStatus", val)
									              setFieldValue("martialStatusLabel", val.label)
								              }}/>
								<ErrorMessageText><ErrorMessage name="martialStatus"/></ErrorMessageText>
								<CustomWrapper>
									<EditableTextInput editableInputHeader={"Twój zawód"}
									                   placeholder={"Wpisz nazwę zawodu"}
									                   onChange={handleChange} type="text" name="profession"
									                   value={values.profession}/>
									<ErrorMessageText><ErrorMessage name="profession"/></ErrorMessageText>
								</CustomWrapper>
								<CategoryHeader className="is-narrow">Czy masz dzieci?</CategoryHeader>
								<RadioButtonsWrapper>
									<RadioButtonsTwoOptionsWrapper>
										{yesOrNoReducer.data.map(({label, value}) => (
											<RadioButtonTwoOptions id={value}
											                       key={value}
											                       activeId={values.haveChildren}
											                       amountName={label}
											                       isSmallMargin={false}
											                       onClick={(val) => {
												                       setFieldValue("haveChildren", val)
												                       setFieldValue("haveChildrenLabel", (val === 1) ? "dzieci" : "brak dzieci")
											                       }}/>
										))}
									</RadioButtonsTwoOptionsWrapper>
								</RadioButtonsWrapper>
								<ErrorMessageText><ErrorMessage name="haveChildren"/></ErrorMessageText>
								<RadioButtonsWrapper>
									<CategoryHeader className="is-narrow">Dane kontaktowe</CategoryHeader>
									<RadioButtonsInRow>
										{contactTypeData.map(({label, value}) => (
											<RadioButtonBullet id={value} key={value} defaultId={1}
											                   activeId={values.contactType} amountName={label}
											                   isMaxWidthBigger={true}
											                   onClick={(val) => setFieldValue("contactType", val)}/>
										))}
									</RadioButtonsInRow>
								</RadioButtonsWrapper>
								{(values.contactType === 1) && <>
									<EditableTextInput editableInputHeader={""}
									                   placeholder={"Podaj swój numer telefonu"} isNotMarginTop={true}
									                   onChange={handleChange} type="number" name="phoneNumber"
									                   value={values.phoneNumber}/>
									<ErrorMessageText><ErrorMessage name="phoneNumber"/></ErrorMessageText> </>}
								{(values.contactType === 2) && <>
									<EditableTextInput editableInputHeader={""} placeholder={"Podaj swój adres email"}
									                   isNotMarginTop={true}
									                   onChange={handleChange} type="email" name="emailAddress"
									                   value={values.emailAddress}/>
									<ErrorMessageText><ErrorMessage name="emailAddress"/></ErrorMessageText> </>}
								<CustomSelect selectLabel="W jakich godzinach preferuje Pani/Pan kontakt?"
								              placeholder={"wybierz godzinę kontaktu"}
								              options={meetingHoursReducer.data}
								              isLoading={meetingHoursReducer.loading}
								              name="meetingHour"
								              isClockShown={true}
								              selectedValue={values.meetingHour}
								              onChange={(val) => {
									              setFieldValue("meetingHour", val)
									              setFieldValue("meetingHourLabel", val.label)
								              }}/>
								<CheckboxWrapper>
									<SingleCheckbox name="dataProcessingAccepted"
									                type="checkbox"
									                required
									                checked={values.dataProcessingAccepted}
									                onChange={handleDataProcessingAccept}>
										<div>Wyrażam zgodę na przetwarzanie powyższych danych osobowych przez Alwis
											Finanse Sp. z o.o. w celu umożliwienia
											kontaktu poprzez email (to znaczy otrzymywania drogą elektroniczną
											informacji handlowych w rozumieniu ustawy
											o świadczeniu usług drogą elektroniczną) i kontaktu poprzez sms i telefon
											(to znaczy na wykorzystywanie telekomunikacyjnych
											urzadzeń końcowych i automatycznych systemów wywołujących dla celów
											marketingu bezpośredniego w rozumieniu
											przepisów ustawy prawo telekomunikacyjne). Zastrzegam, że bez mojej zgody
											dane te nie mo ga być udostępniane komukolwiek.
											Przysługuje mi prawo dostępu do treści danych oraz możliwość ich
											poprawiania. Zgody powyższej udzielam dobrowolnie.
										</div>
									</SingleCheckbox>
									<ErrorMessageText><ErrorMessage name="dataProcessingAccepted"/></ErrorMessageText>
								</CheckboxWrapper>
								<NavigationButtonsWrapper className="wrap-button">
									<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"}
									                    to="/step-3"/>
									<NextStepButton className="send-form" type="submit"
									                isDisabled={isSubmitting}
									                isVisible={true} textBtn={"Wyślij formularz"}/>
								</NavigationButtonsWrapper>
							</form>)}
					</Formik>
				</ContentWrapper>
			</TabWrapper>
			{isPopupShown && <Popup onClick={handleClosePopup}/>}
		</>
	)
};

export default FourthStepFirstTab;