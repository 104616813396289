import React from "react"
import styled from "styled-components"

const SingleRadioButtonWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	max-width: ${({isMaxWidthBigger}) => isMaxWidthBigger ? "100px" : "80px"};
	margin-right: 20px;
	@media(min-width: 1280px) {
		width: unset;
	}
`;

const SingleRadioButton = styled.div`
	color: ${({theme}) => theme.colors.formLightGrey};
	width: 25px;
	height: 25px;
	top: 3px;
	margin-bottom: 15px;
	border-radius: 50%;
	border: 1px solid ${({theme}) => theme.colors.formLightGreen};
	background-color: ${({theme}) => theme.colors.formTransparent};
	position: relative;
	cursor: pointer;
	@media(min-width: 1280px) {
		margin-bottom: 0;
	}
	&.active {
		border: 1px solid ${({theme}) => theme.colors.formDarkOrange};
		&:before {
			content: '';
			position: absolute;
			width: 11px;
			height: 11px;
			top: 6px;
			left: 6px;
			border-radius: 50%;
			background-color: ${({theme}) => theme.colors.formDarkOrange};
		}
	}
`;

const RadioButtonLabel = styled.p`
	color: ${({theme}) => theme.colors.formDarkGreen};
	margin: 6px 10px 0 8px;
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	font-size: 15px;
	line-height: 18px;
	cursor: pointer;
`;

const RadioButtonBullet = ({ amountName, id, activeId, onClick, defaultId, isMaxWidthBigger }) => {
	return (
		<SingleRadioButtonWrapper isMaxWidthBigger={isMaxWidthBigger} key={id} onClick={() => onClick(id)}>
			<SingleRadioButton className={((activeId || defaultId) === id) ? "active" : ""}/>
			<RadioButtonLabel>{amountName}</RadioButtonLabel>
		</SingleRadioButtonWrapper>
	)
};

export default RadioButtonBullet;
